import { max, min, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';
import store from '@/store';

setInteractionMode('eager');

// export default function init(transProvider) {
extend('MAX_LENGTH', {
  ...max,
  message: store.state.transProvider.$t('base.validation.MAX_LENGTH'),
});

extend('MIN_LENGTH', {
  ...min,
  message: store.state.transProvider.$t('base.validation.MIN_LENGTH'),
});

extend('REQUIRED', {
  ...required,
  message: store.state.transProvider.$t('base.validation.REQUIRED'),
});
// };
